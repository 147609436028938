import { createContext, useContext, useLayoutEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { authloader } from './Loader';
const Context = createContext('');

export const useContextHooks = () => {
    const loaderData = useLoaderData();
    const [context, setContext] = useContext(Context);

    useLayoutEffect(() => {
        authloader(true)
        document.title = "Joinery By Design | " + loaderData.title;
        document.description = "Joinery By Design | " + loaderData.description;
        setContext(prev => ({ ...prev, page: loaderData }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return context
}

export default Context