

function Banner({ url, title }) {

    return (<>
        {/* <div className="section page-banner-section" style={{ backgroundImage: `url(${url ?? '/assets/images/page-banner.jpg'})` }}>
            <div className="container">
                <div className="page-banner-content"  data-aos="fade-up">
                    <h2 className="title">{title}</h2>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">{title}</li>
                    </ul>
                </div>
            </div>
        </div> */}
    </>)
}

export default Banner;